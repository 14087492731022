import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Container from "@material-ui/core/Container"
import { FormattedMessage } from "gatsby-plugin-react-intl"
import { useIntl } from "gatsby-plugin-react-intl"
const NotFoundPage = () => {
  const intl = useIntl()
  return (
    <Layout>
      <SEO
        lang={intl.locale}
        description=""
        title={intl.formatMessage({
          id: "404_title",
        })}
      />
      <Container>
        <section className="not-found">
          <h1>
            <FormattedMessage id="404_title" defaultMessage="NIE ZNALEZIONO" />
          </h1>
          <p>
            <FormattedMessage
              id="404_message"
              defaultMessage="Taka strona nie istnieje w naszym serwisie..."
            />
          </p>
        </section>
      </Container>
    </Layout>
  )
}

export default NotFoundPage
